// extracted by mini-css-extract-plugin
export var adornmentIcon = "membership-details-form-module--adornment-icon--7b534";
export var ageResContainer = "membership-details-form-module--age-res-container--2f759";
export var alignContainer = "membership-details-form-module--align-container--a3d40";
export var bodyBase = "membership-details-form-module--body-base--58e6b membership-details-form-module--site-font--18bf1";
export var bodyLarge = "membership-details-form-module--body-large--36fe7 membership-details-form-module--body-base--58e6b membership-details-form-module--site-font--18bf1";
export var bodyLargeBold = "membership-details-form-module--body-large-bold--64d8f membership-details-form-module--body-base--58e6b membership-details-form-module--site-font--18bf1";
export var bodyRegular = "membership-details-form-module--body-regular--46c66 membership-details-form-module--body-base--58e6b membership-details-form-module--site-font--18bf1";
export var bodyRegularBold = "membership-details-form-module--body-regular-bold--48443 membership-details-form-module--body-base--58e6b membership-details-form-module--site-font--18bf1";
export var bodySmall = "membership-details-form-module--body-small--0befc membership-details-form-module--body-base--58e6b membership-details-form-module--site-font--18bf1";
export var bodySmallBold = "membership-details-form-module--body-small-bold--a5c4d membership-details-form-module--body-base--58e6b membership-details-form-module--site-font--18bf1";
export var borderTop = "membership-details-form-module--border-top--ef039";
export var breakWordContainer = "membership-details-form-module--break-word-container--fef61";
export var buttonIconBase = "membership-details-form-module--button-icon-base--67300";
export var clickLink = "membership-details-form-module--click-link--9e6a0";
export var container = "membership-details-form-module--container--50b7c membership-details-form-module--flex-col--ee25a membership-details-form-module--primary-border--66bd1";
export var dropdown = "membership-details-form-module--dropdown--eaa2e";
export var dropdownBase = "membership-details-form-module--dropdown-base--6aa0b";
export var dropdownSelectBase = "membership-details-form-module--dropdown-select-base--0cf50 membership-details-form-module--text-input--c73f7";
export var editBtn = "membership-details-form-module--edit-btn--d5d7e";
export var errorMessage = "membership-details-form-module--error-message--2a9c3 membership-details-form-module--form-error-message--9d02b";
export var explanation = "membership-details-form-module--explanation--7fae8";
export var explanationDropdown = "membership-details-form-module--explanation-dropdown--60120";
export var flexCol = "membership-details-form-module--flex-col--ee25a";
export var formErrorMessage = "membership-details-form-module--form-error-message--9d02b";
export var h3 = "membership-details-form-module--h3--c95ce";
export var h4 = "membership-details-form-module--h4--83c32";
export var header = "membership-details-form-module--header--b6859 membership-details-form-module--membership-header--5d7aa";
export var heading = "membership-details-form-module--heading--8c354 membership-details-form-module--membership-heading--34224";
export var hoverLink = "membership-details-form-module--hover-link--b19b3";
export var hoverRow = "membership-details-form-module--hover-row--cc0fe";
export var input = "membership-details-form-module--input--0e091";
export var inputContainer = "membership-details-form-module--input-container--2abd7";
export var membershipContainer = "membership-details-form-module--membership-container--ee65a membership-details-form-module--flex-col--ee25a membership-details-form-module--primary-border--66bd1";
export var membershipHeader = "membership-details-form-module--membership-header--5d7aa";
export var membershipHeading = "membership-details-form-module--membership-heading--34224";
export var membershipLabel = "membership-details-form-module--membership-label--66004 membership-details-form-module--membership-label--66004";
export var moreFiltersBorderClass = "membership-details-form-module--more-filters-border-class--d8abf";
export var pageBg = "membership-details-form-module--page-bg--3dd4b";
export var pointer = "membership-details-form-module--pointer--8fb70";
export var primaryBorder = "membership-details-form-module--primary-border--66bd1";
export var select = "membership-details-form-module--select--2ca98";
export var shadowBoxLight = "membership-details-form-module--shadow-box-light--52310";
export var siteFont = "membership-details-form-module--site-font--18bf1";
export var slideDownAndFade = "membership-details-form-module--slideDownAndFade--e46c9";
export var slideLeftAndFade = "membership-details-form-module--slideLeftAndFade--9aefa";
export var slideRightAndFade = "membership-details-form-module--slideRightAndFade--b546d";
export var slideUpAndFade = "membership-details-form-module--slideUpAndFade--d2c02";
export var statusDecoration = "membership-details-form-module--status-decoration--419fe";
export var subContainer = "membership-details-form-module--sub-container--67014 membership-details-form-module--flex-col--ee25a";
export var testStyle = "membership-details-form-module--test-style--6ae72";
export var textInput = "membership-details-form-module--text-input--c73f7";
export var textInverted = "membership-details-form-module--text-inverted--5c6aa";
export var textMediumDark = "membership-details-form-module--text-medium-dark--99ec7";
export var tooltipFont = "membership-details-form-module--tooltipFont--94d51";
export var topLabel = "membership-details-form-module--top-label--f797f";
export var unstyledButton = "membership-details-form-module--unstyled-button--6cc44";
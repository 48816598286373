import React from 'react';

import { Grid } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import Button from 'src/components/button/button';
import MembershipCostForm from 'src/components/membership-cost-form/membership-cost-form';
import MembershipDatesForm from 'src/components/membership-dates-form/membership-dates-form';
import MembershipDetailsForm from 'src/components/membership-details-form/membership-details-form';
import MembershipDiscoundsForm from 'src/components/membership-discounts-form/membership-discounts-form';
import MembershipForm from 'src/components/membership-form/membership-form';
import { AddMembershipState, addMembershipValues as initialValues } from 'src/utils/typedefs/membership';
import * as Yup from 'yup';

import * as styles from './add-membership.module.less';

const AddMembership = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    membershipDetails: Yup.object().shape({
      name: Yup.string().required(t('is required', { type: 'name' })),
      code: Yup.string().required(t('is required', { type: 'code' })),
      status: Yup.string().required(t('is required', { type: 'status' })),
      type: Yup.string().required(t('is required', { type: 'type' })),
      category: Yup.string().required(t('is required', { type: 'category' })),
      ageRestriction: Yup.boolean(),
      minAge: Yup.string().when('ageRestriction', {
        is: true,
        then: Yup.string().required(t('is required', { type: 'min age' })),
        otherwise: Yup.string(),
      }),
      maxAge: Yup.string().when('ageRestriction', {
        is: true,
        then: Yup.string().required(t('is required', { type: 'max age' })),
        otherwise: Yup.string(),
      }),
    }),
    dates: Yup.object().shape({
      renewals: Yup.string(),
      startDate: Yup.string()
        .required(t('is required', { type: 'start date' }))
        .nullable(),
      endDate: Yup.string().when('renewals', {
        is: 'annually',
        then: Yup.string().nullable(),
        otherwise: Yup.string()
          .required(t('is required', { type: 'end date' }))
          .nullable(),
      }),
      gracePeriod: Yup.string(),
    }),
    cost: Yup.object().shape({
      pricingModel: Yup.string(),
      cost: Yup.string().required(t('is required', { type: 'cost' })),
    }),
    addDiscount: Yup.object().shape({
      name: Yup.string().required(t('is required', { type: 'name' })),
      discountPercentage: Yup.string().required(t('is required', { type: 'discount' })),
    }),
  });

  return (
    <Grid container className={styles.mainContainer}>
      <Breadcrumbs
        paths={[
          { name: 'Memberships', to: '/memberships/membership' },
          { name: 'Create Membership', to: '/memberships/create-membership' },
        ]}
      />
      <h1 className={styles.mainHeading}>Create Membership</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, action) => {
          console.log(values.dates);
        }}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form translate="" className={styles.form} onClick={() => console.log(formikProps.values)}>
            <MembershipDetailsForm formikProps={formikProps} />
            <MembershipDatesForm formikProps={formikProps} />
            <MembershipForm formikProps={formikProps} />
            <MembershipCostForm formikProps={formikProps} />
            <MembershipDiscoundsForm formikProps={formikProps} />
            <Button size="md" type="submit" spacing={{ margins: { lg: 'top' } }}>
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default AddMembership;

import React from 'react';

import AddMembership from 'src/components/add-membership/add-membership';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const CreateMembership = () => {
  return (
    <Layout>
      <SEO title="Create Membership" />
      <PrivateRoute>
        <AddMembership />
      </PrivateRoute>
    </Layout>
  );
};

export default CreateMembership;

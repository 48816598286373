import React from 'react';

import { useTranslation } from 'react-i18next';
import FormInputLabel from 'src/components/form-info-label/form-info-label';
import InputLabel from 'src/components/input-label/input-label';
import { AddMembershipState, MembershipDetails } from 'src/utils/typedefs/membership';

import * as styles from './membership-details-info.module.less';

interface Props {
  values: AddMembershipState;
}

const MembershipDetailsInfo: React.FC<Props> = ({ values }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <FormInputLabel className={styles.itemA} label={t('name')}>
        {t(values.membershipDetails.name)}
      </FormInputLabel>

      <FormInputLabel className={styles.itemB} label={t('status')}>
        {t(values.membershipDetails.status)}
      </FormInputLabel>

      <FormInputLabel className={styles.itemC} label={t('category')}>
        {t(values.membershipDetails.category)}
      </FormInputLabel>

      <FormInputLabel className={styles.itemD} label={t('code')}>
        {t(values.membershipDetails.code)}
      </FormInputLabel>

      <FormInputLabel className={styles.itemE} label={t('type')}>
        {t(values.membershipDetails.type)}
      </FormInputLabel>
    </div>
  );
};

export default MembershipDetailsInfo;

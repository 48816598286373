import React, { InputHTMLAttributes } from 'react';

import cx from 'classnames';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import * as styles from './input.module.less';

export interface InputProps extends SpacingProps, InputHTMLAttributes<HTMLInputElement> {
  field?: {
    name: string;
    value: string;
  };
  classname?: string;
  props?: any;
}

const Input: React.FC<InputProps> = ({ field, classname, ...props }) => {
  return <input {...field} {...props} className={cx(styles.input, classname)} type="text" />;
};

export default Input;

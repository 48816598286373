import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { FastField, Field, FormikProps } from 'formik';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
// import Dropdown from '../dropdown/dropdown'
import Edit from 'src/components/edit/edit';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import Input from 'src/components/input';
import InputLabel from 'src/components/input-label/input-label';
import MembershipDetailsInfo from 'src/components/membership-details-info/membership-details-info';
import RadioButtonGroup from 'src/components/radio-button-group/radio-button-group';
import TextEditor from 'src/components/rich-text-editor/rich-text-editor';
import Slider from 'src/components/slider/slider';
import { getFirstOptionValue } from 'src/utils/helper/membership';
import { AddMembershipState } from 'src/utils/typedefs/membership';

import { Dropdown, TextInput } from '@clubspark-react/clubspark-react-tools';

import * as styles from './membership-details-form.module.less';

interface Props {
  formikProps: FormikProps<AddMembershipState>;
}

const MembershipDetailsForm: React.FC<Props> = ({ formikProps }: Props) => {
  const { t } = useTranslation();
  const {
    values,
    values: { step, membershipDetails },
    setFieldValue,
    setFieldTouched,
    touched: { membershipDetails: touched },
    errors: { membershipDetails: errors },
  } = formikProps;
  const [organizationType, setOrganizationType] = React.useState('female');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationType((event.target as HTMLInputElement).value);
  };
  const SliderLabel = { true: t('yes'), false: t('no') };

  const STATUS_OPTIONS = [
    { label: t('public'), value: 'public' },
    { label: t('direct link'), value: 'direct link' },
    { label: t('hidden'), value: 'hidden' },
    { label: t('archived'), value: 'archived' },
  ];

  const TYPE_OPTIONS = [
    { value: 'individual', label: t('individual') },
    { value: 'organisation', label: t('organisation') },
  ];

  const CATEGORY_OPTIONS_INDIVIDUAL = [
    { label: t('player'), value: 'player' },
    { label: t('coach'), value: 'coach' },
    { label: t('fan'), value: 'fan' },
  ];

  const CATEGORY_OPTIONS_ORGANISATION = [{ label: t('club'), value: 'club' }];

  // Set default values for select fields
  useEffect(() => {
    setFieldValue('membershipDetails.status', getFirstOptionValue(STATUS_OPTIONS));
    setFieldValue('membershipDetails.type', getFirstOptionValue(TYPE_OPTIONS));
    setFieldValue('membershipDetails.category', getFirstOptionValue(CATEGORY_OPTIONS_INDIVIDUAL));
  }, []);

  useEffect(() => {
    setFieldValue('membershipDetails.category', getFirstOptionValue(GET_CATEGORY_OPTIONS()));
  }, [values.membershipDetails.type]);

  const GET_CATEGORY_OPTIONS = () => {
    console.log(values.membershipDetails.type);
    if (values.membershipDetails.type === 'individual') {
      return CATEGORY_OPTIONS_INDIVIDUAL;
    } else {
      return CATEGORY_OPTIONS_ORGANISATION;
    }
  };

  const changeStep = () => {
    setFieldValue('step', 0);
  };

  const nextStep = () => {
    // Check if Yup validation object has any errors, if true - return and touch all form fields
    if (!_.isEmpty(errors)) {
      return Object.keys(membershipDetails).forEach((field) => setFieldTouched(`membershipDetails.${field}`, true));
    }

    setFieldValue(`stepHistory.${step}`, true);
    setFieldValue('step', step + 1);
  };

  const renderMinMaxAge = () => {
    if (values.membershipDetails.ageRestriction) {
      return (
        <div className={styles.ageResContainer}>
          <div className={styles.subContainer}>
            <InputLabel className={styles.membershipLabel}>{t('min age')}*</InputLabel>
            <FastField name="membershipDetails.minAge" size="xs" component={Input} />
            {errors?.minAge && touched?.minAge && <FormErrorMessage message={errors.minAge} />}
          </div>
          <div className={styles.subContainer}>
            <InputLabel className={styles.membershipLabel}>{t('max age')}*</InputLabel>
            <FastField name="membershipDetails.maxAge" size="xs" component={Input} />
            {errors?.maxAge && touched?.maxAge && <FormErrorMessage message={errors.maxAge} />}
          </div>
        </div>
      );
    }
  };

  const renderForm = () => {
    if (step === 0) {
      return (
        <div className={styles.alignContainer}>
          <InputLabel className={styles.topLabel}>{t('name')}*</InputLabel>
          <Grid container>
            <Grid item xs={12} sm={7} md={4} lg={3} xl={3}>
              <Field
                name="membershipDetails.name"
                component={TextInput}
                // className={styles.input}
                outlined
                disableUnderline
                fullWidth
              />
            </Grid>
          </Grid>
          {errors?.name && touched?.name && <FormErrorMessage message={errors.name} />}
          <InputLabel className={styles.membershipLabel}>{t('code')}*</InputLabel>
          <Grid container className={styles.inputContainer}>
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
              <Field
                name="membershipDetails.code"
                component={TextInput}
                // className={styles.input}
                outlined
                disableUnderline
                fullWidth
              />
            </Grid>
          </Grid>
          {errors?.code && touched?.code && <FormErrorMessage message={errors.code} />}
          <InputLabel className={styles.membershipLabel}>{t('status')}*</InputLabel>
          <Grid container>
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
              <Field
                name="membershipDetails.status"
                options={STATUS_OPTIONS}
                setFieldValue={setFieldValue}
                component={Dropdown}
                selectClassname={styles.select}
                dropdownClassname={styles.dropdown}
              />
            </Grid>
          </Grid>
          {errors?.status && touched?.status && <FormErrorMessage message={errors.status} />}
          <InputLabel className={styles.membershipLabel}>{t('type')}</InputLabel>
          <Field
            name="membershipDetails.type"
            options={TYPE_OPTIONS}
            // setFieldValue={setFieldValue}
            component={RadioButtonGroup}
            value={organizationType}
            handleChange={handleChange}
          />
          <InputLabel className={styles.membershipLabel}>{t('category')}*</InputLabel>
          <Grid container>
            <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
              <Field
                name="membershipDetails.category"
                options={GET_CATEGORY_OPTIONS()}
                setFieldValue={setFieldValue}
                component={Dropdown}
                selectClassname={styles.select}
                dropdownClassname={styles.dropdown}
              />
            </Grid>
          </Grid>
          {errors?.category && touched?.category && <FormErrorMessage message={errors.category} />}
          <InputLabel className={styles.membershipLabel}>{t('description')}</InputLabel>
          <TextEditor />
          <InputLabel className={styles.membershipLabel}>{t('benefits')}</InputLabel>
          <TextEditor />
          <InputLabel className={styles.membershipLabel}>{t('age restriction')}</InputLabel>
          <Field
            name="membershipDetails.ageRestriction"
            setFieldValue={setFieldValue}
            component={Slider}
            label={SliderLabel}
          />
          {renderMinMaxAge()}
          <Button size="md" spacing={{ margins: { lg: 'top' } }} type="button" onClick={nextStep}>
            {t('continue')}
          </Button>
        </div>
      );
    } else {
      return <MembershipDetailsInfo values={values} />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.heading}>{t('membership details')}</h1>
        {step !== 0 && <Edit className={styles.editBtn} action={changeStep} />}
      </div>
      {renderForm()}
    </div>
  );
};
export default MembershipDetailsForm;

// extracted by mini-css-extract-plugin
export var bodyBase = "membership-details-info-module--body-base--c81e4 membership-details-info-module--site-font--e68d6";
export var bodyLarge = "membership-details-info-module--body-large--e37dd membership-details-info-module--body-base--c81e4 membership-details-info-module--site-font--e68d6";
export var bodyLargeBold = "membership-details-info-module--body-large-bold--f0c2f membership-details-info-module--body-base--c81e4 membership-details-info-module--site-font--e68d6";
export var bodyRegular = "membership-details-info-module--body-regular--06a0a membership-details-info-module--body-base--c81e4 membership-details-info-module--site-font--e68d6";
export var bodyRegularBold = "membership-details-info-module--body-regular-bold--01eab membership-details-info-module--body-base--c81e4 membership-details-info-module--site-font--e68d6";
export var bodySmall = "membership-details-info-module--body-small--e3e80 membership-details-info-module--body-base--c81e4 membership-details-info-module--site-font--e68d6";
export var bodySmallBold = "membership-details-info-module--body-small-bold--aa727 membership-details-info-module--body-base--c81e4 membership-details-info-module--site-font--e68d6";
export var borderTop = "membership-details-info-module--border-top--ee012";
export var breakWordContainer = "membership-details-info-module--break-word-container--7a8e0";
export var buttonIconBase = "membership-details-info-module--button-icon-base--45099";
export var clickLink = "membership-details-info-module--click-link--9858f";
export var container = "membership-details-info-module--container--55992";
export var dropdownBase = "membership-details-info-module--dropdown-base--50ed2";
export var dropdownSelectBase = "membership-details-info-module--dropdown-select-base--5ee64 membership-details-info-module--text-input--5e11c";
export var flexCol = "membership-details-info-module--flex-col--939a0";
export var formErrorMessage = "membership-details-info-module--form-error-message--0a488";
export var h3 = "membership-details-info-module--h3--4cd3b";
export var h4 = "membership-details-info-module--h4--71cac";
export var hoverLink = "membership-details-info-module--hover-link--d0d1a";
export var hoverRow = "membership-details-info-module--hover-row--3d060";
export var itemA = "membership-details-info-module--item-a--747a8";
export var itemB = "membership-details-info-module--item-b--b31c4";
export var itemC = "membership-details-info-module--item-c--04d22";
export var itemD = "membership-details-info-module--item-d--17ffd";
export var itemE = "membership-details-info-module--item-e--64541";
export var label = "membership-details-info-module--label--948a5";
export var labelValue = "membership-details-info-module--label-value--ad719";
export var membershipContainer = "membership-details-info-module--membership-container--c6714 membership-details-info-module--flex-col--939a0 membership-details-info-module--primary-border--60c6c";
export var membershipHeader = "membership-details-info-module--membership-header--a2819";
export var membershipHeading = "membership-details-info-module--membership-heading--93304";
export var membershipLabel = "membership-details-info-module--membership-label--b1869";
export var moreFiltersBorderClass = "membership-details-info-module--more-filters-border-class--fa759";
export var pageBg = "membership-details-info-module--page-bg--6c4f3";
export var pointer = "membership-details-info-module--pointer--95088";
export var primaryBorder = "membership-details-info-module--primary-border--60c6c";
export var shadowBoxLight = "membership-details-info-module--shadow-box-light--62a9b";
export var siteFont = "membership-details-info-module--site-font--e68d6";
export var slideDownAndFade = "membership-details-info-module--slideDownAndFade--e0d29";
export var slideLeftAndFade = "membership-details-info-module--slideLeftAndFade--f1305";
export var slideRightAndFade = "membership-details-info-module--slideRightAndFade--67afc";
export var slideUpAndFade = "membership-details-info-module--slideUpAndFade--c1cc7";
export var statusDecoration = "membership-details-info-module--status-decoration--096e6";
export var textInput = "membership-details-info-module--text-input--5e11c";
export var textInverted = "membership-details-info-module--text-inverted--e9bf2";
export var textMediumDark = "membership-details-info-module--text-medium-dark--c05bc";
export var tooltipFont = "membership-details-info-module--tooltipFont--88327";
export var unstyledButton = "membership-details-info-module--unstyled-button--28949";